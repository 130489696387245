import { isPC } from "@/libs/util.device";
import { isEmpty } from "lodash";

export default {
  namespaced: true,
  state: {
    // 是否pc端
    isPc: "",
  },
  mutations: {
    curPcFlag(state) {
      state.isPc = isPC();
    },
  },
  actions: {},
};
