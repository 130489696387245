import axios from "axios";
import { get, set } from "lodash";
import { errorLog, errorCreate, errorMsgCreate, loadingMsgCreate } from "./tools";
import router from "@/router/index";
import store from "../store";
// import { Toast } from "vant";

const serviceConfigApi = "https://api.yyjishu.com/yyjishu";
let toast;

/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create();
  // 请求拦截
  service.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      // 发送失败
      console.log(error);
      return Promise.reject(error);
    }
  );

  // 响应拦截
  service.interceptors.response.use(
    (response) => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data;
      // 这个状态码是和后端约定的
      const { code } = dataAxios;
      // 根据 code 进行判断
      // 返回值不是一个正常值的判断

      if (!_.isObject(dataAxios) && !_.isArray(dataAxios)) {
        errorMsgCreate(`返回值格式错误!`);
        return false;
      }

      if (code === undefined) {
        return dataAxios;
      } else {
        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        switch (code) {
          case 0:
            return dataAxios.data;
          case 1:
            router.push("/misstoken");
            errorMsgCreate(`${dataAxios.msg}`);
            return false;
          case 2:
            errorMsgCreate(`${dataAxios.msg}`);
            return false;
          case 3:
            errorMsgCreate(`${dataAxios.msg}`);
            return false;
          default:
            // 不是正确的 code
            errorCreate(`${dataAxios.msg}: ${response.config.url}`);
            break;
        }
      }
    },
    (error) => {
      const status = get(error, "response.status");
      switch (status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
      if (error.message === "Network Error") {
        error.message = "网络错误或请求地址不存在";
      } else if (_.startsWith(error.message, "timeout of")) {
        error.message = "接口返回等待超时";
      }
      // errorMsgCreate(error.message);
      return Promise.reject(error);
    }
  );
  return service;
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function(config) {
    const configDefault = {
      headers: {
        "Content-Type": get(config, "headers.Content-Type", "application/json"),
      },
      timeout: 5000,
      baseURL: process.env.NODE_ENV === "production" ? serviceConfigApi : "/apis",
      data: {},
    };
    return service(Object.assign(configDefault, config));
  };
}
// 用于真实网络请求的实例和请求方法
export const service = createService();
export const request = createRequestFunction(service);
