<template>
  <el-row id="app">
    <el-col :span="24">
      <router-view />
    </el-col>
  </el-row>
</template>

<script>
export default {
  mounted() {
    //获取屏幕宽度
    let htmlwidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    // 获取html的dom
    let htmldom = document.getElementsByTagName("html")[0];
    //设置html的font-size
    ("1000/32=32.25");

    if (htmlwidth > 1000) {
      htmldom.style.fontSize = "32px";
      // htmldom.style.fontSize = htmlwidth / 23.4375 + "px";
    } else {
      htmldom.style.fontSize = htmlwidth / 31.25 + "px";
    }
    window.addEventListener("resize", e => {
      let htmlwidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      if (htmlwidth > 1000) {
        htmldom.style.fontSize = "32px";
        // htmldom.style.fontSize = htmlwidth / 23.4375 + "px";
      } else {
        htmldom.style.fontSize = htmlwidth / 31.25 + "px";
      }
    });
    document.addEventListener("touchstart", function(event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    });
    document.addEventListener("gesturestart", function(event) {
      event.preventDefault();
    });
  }
};
</script>


<style lang="scss">
@function px2rem($px) {
  $rem: 16px;
  @return ($px/ $rem) + rem;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-color: #f5f5f4;
  resize: both;
  overflow: auto;
}
</style>
