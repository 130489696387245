export function isPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
export function whatSys() {
  var u = navigator.userAgent;
  if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    return "Android";
    //安卓手机
  } else if (u.indexOf("iPhone") > -1) {
    return "iPhone";
    //苹果手机
  } else if (u.indexOf("Windows Phone") > -1) {
    return "WindowsPhone";
    //winphone手机
  } else {
    return "unknow";
  }
}
export function isWeiXin() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("micromessenger") != -1) {
    return true;
  } else {
    return false;
  }
}

const device = {};
export default device;
